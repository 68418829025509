body {
    margin: 0;
    font-family: "Helvetica Neue",Helvetica,Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import 'css/colors.css';
@import 'css/loginPage.css';
@import 'css/print.css';

.adScreenshotImg {
    width: 100%;
}

.adViolationReport table tr:nth-child(odd) {
    background-color: var(--input-background-disabled);
}

.tableActionButton {
    font-size: .875rem;
    color: var(--accent-green-two);
    font-weight: bold;
}


.multivaluecustominput .multiple-value-text-input p{
    margin:0px;
}
.multivaluecustominput .multiple-value-text-input .multiple-value-text-input-item {
    color: rgba(0, 0, 0, 0.87);
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0 8px 0 10px;
    font-size: 0.8125rem;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    background-color: #e0e0e0;
    margin-bottom: 5px;
}
.multivaluecustominput .multiple-value-text-input .multiple-value-text-input-item-delete-button{
    background: #b0b3b4;
    color: #fff;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 50%;
    margin-left: 5px;
}
.multivaluecustominput .multiple-value-text-input input{
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    background-color: transparent;
    transition: all .3s ease;
    outline: none !important;
    height: 28px;
}
.multivaluecustominput .multiple-value-text-input input:hover{
    border-bottom: 2px solid #2d2d2d;
}
.text-right1{
    text-align: right;
}
.mb-2{
    margin-bottom: 20px !important;
}
.pt-2{
    padding-top: 15px;
}
.customform{
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    outline: none !important;
    height: 27px;
}
.customform:hover{
    border-bottom: 2px solid #2d2d2d;
}
.bt-1{
    border-top: 1px solid #ccc;
}

.spinner-border-sm {
    width: 4rem !important;
    height: 4rem !important;
    border-width: 0.5em !important;
}